
      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "Blockable": [
      "ArticleBlock",
      "ArticleListBlock",
      "AudioBlock",
      "EmbeddedContentBlock",
      "FaqBlock",
      "FileBlock",
      "FormBlockBlock",
      "ImageBlock",
      "ImageGalleryBlock",
      "MailchimpSubscribeBlock",
      "MarketHypeSubscribeBlock",
      "NewsListBlock",
      "QuickLinksBlockBlock",
      "ScenkonstArticleListLargeBlock",
      "ScenkonstCalendarBlock",
      "ScenkonstDigitalStageBlock",
      "ScenkonstInlineTermBlock",
      "ScenkonstOnStageBlock",
      "ScenkonstPerformanceListingBlock",
      "ScenkonstSponsorBlock",
      "ScenkonstSpotlightBlock",
      "ScenkonstStaffBlock",
      "ScenkonstSubscriptionsListBlock",
      "VideoBlock",
      "WysiwygBlock",
      "WysiwygTwoColumnBlock"
    ],
    "Contentable": [
      "NewsArticleContent",
      "NewsListingContent",
      "ScenkonstInvitationContainerContent",
      "ScenkonstInvitationContent",
      "ScenkonstOtPerformanceContent",
      "ScenkonstOtPerformanceListingContent",
      "ScenkonstStaffCategorySingleListingContent",
      "ScenkonstStaffContent",
      "ScenkonstStaffListingContent",
      "ShortUrlContent",
      "StandardContent",
      "StartpageContent"
    ]
  }
};
      export default result;
    